import * as React from "react";
import { containerStyle, labelStyle, valueStyle } from "./style.css";

interface Props {
    label: string;
    value: string;
}

const CompanyDetail = ({ label, value }: Props) => {
    return (
        <div className={containerStyle}>
            <p className={labelStyle}>{label}</p>
            <p className={valueStyle}>{value}</p>
        </div>
    );
};

export default CompanyDetail;
