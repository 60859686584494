import * as React from "react";
import CompanyDetail from "../components/CompanyDetail";
import Layout from "../components/Layout";
import useColor from "../context/ColorContext";

const ContactPage = () => {
    const { setColor } = useColor();

    React.useEffect(() => {
        setColor?.("blue");
    }, []);

    return (
        <Layout>
            <h1 style={{ marginTop: 120, marginBottom: 32 }}>Kontakt</h1>
            <div>
                <CompanyDetail
                    label="Nazwa firmy"
                    value="Esenti24 sp z o.o sp.k."
                />
                <CompanyDetail
                    label="Adres firmy"
                    value="ul. Leona Wyczółkowskiego 14-16 62-800 Kalisz"
                />
                <CompanyDetail label="Numer telefonu" value="+48 512 006 106" />
                <CompanyDetail
                    label="Adres email"
                    value="maciej@reklama-goldfish.pl"
                />
                <CompanyDetail label="Numer KRS" value="0000788249" />
                <CompanyDetail label="NIP" value="6182181819" />
                <CompanyDetail label="Numer REGON" value="383452136" />
                <CompanyDetail
                    label="Numer rachunku bankowego"
                    value="57 1090 1128 0000 0001 4946 7190"
                />
            </div>
        </Layout>
    );
};

export default ContactPage;
